<template>
  <div class="modal">
    <div class="modal__mask">
      <div class="modal__wrapper">
        <div class="modal__container">
          <div class="modal__header">
            <div class="modal__header-content">
              <div class="modal__title">
                Add Charterer
              </div>
              <div class="modal__header-panel">
                <div
                  class="modal__close-button"
                  v-on:click="
                    () => this.$router.go(-1)
                  "
                >
                  <div class="close-button__img"></div>
                  <span>Cancel</span>
                </div>
                <div class="modal__save-button" v-on:click="() => this.sendToQB()">
                  <CustomLoader v-if="sendingToQB" />
                  <div v-else class="save-button__body">
                    <div class="save-button__img"></div>
                    <span>Save</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal__body">
            <div class="switch-bar modal__switch-bar">
              <template>
                <div>
                </div>
              </template>
            </div>
            <div class="switcher-content">
              <div class="switcher-content__choose">
                <div>
                  <div class="switcher-content__sub-tab-body">
                    <table class="switcher-content__sub-tab-table">
                      <tr>
                        <td>
                          <div>
                            <div class="field-title">Name</div>
                            <input
                              v-model="formData.name"
                              type="text"
                              class="switcher-content__data-input"
                            />
                          </div>
                        </td>
                        <td>
                          <div>
                            <div class="field-title">Display on the website</div>
                            <input
                              v-model="formData.display"
                              type="checkbox"
                              class="switcher-content__data-input checkbox"
                            />
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CreateRecordPopUp
      v-if="showCreateRecordPopUp"
      record-name="Charterer"
      :open="showCreateRecordPopUp"
      :createdRecord="this.createdRecord"
    />

  </div>
</template>

<script>
import CustomLoader from "@/components/loader/CustomLoader.vue";
import CreateRecordPopUp from "@/components/pop-up/CreateRecordPopUp.vue";

export default {
  components: {
    CustomLoader,
    CreateRecordPopUp,
  },

  props: {

  },

  data: function () {
    return {
      charterers: [],
      formData: {
        name: "",
        display: false,
      },
      sendingToQB: false,
      createdRecord: false,
      showCreateRecordPopUp: false,
    };
  },

  mounted() {

  },

  updated() {

  },

  computed: {

  },

  watch: {
 
  },

  methods: {

    // async sendToQB() {

    //   this.sendingToQB = true;

    //   let data = {
    //     "name": this.formData.name,
    //     "role": this.formData.role,
    //     "email": this.formData.email,
    //     "assword": this.formData.password,
    //     "approved": this.formData.approved,
    //     "add_lifting": this.formData.add_lifting,
    //     "edit_lifting": this.formData.edit_lifting,
    //     "view_lifting": this.formData.view_lifting,
    //   }

    //   if (this.formData.role == "Client") {
    //     data.charterers = this.formData.charterers.slice();
    //   }

    //   var requestOptions = {
    //     method: "POST",
    //     redirect: "follow",
    //     headers: { 
    //       "Content-Type": "application/json",
    //       "Authorization": "Bearer " + localStorage.token
    //     },
    //     body: JSON.stringify(data), 
        
    //   };

    //   console.log("DATA", data)

    //   fetch(
    //     process.env.VUE_APP_API_DOMAIN + "/v1/users",
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((result) => {
    //       console.log("resultCreatedUser", result);
    //       this.sendingToQB = false;
    //       this.createdRecord = true;

    //       this.showCreateRecordPopUp = true;

    //       setTimeout(() => {
    //         this.showCreateRecordPopUp = false;
    //       }, 3000);

    //       this.formData.name = '';
    //       this.formData.role = '';
    //       this.formData.email = '';
    //       this.formData.password = '';
    //       this.formData.charterers = [];
    //       this.formData.approved = '';
    //       this.formData.add_lifting = '';
    //       this.formData.edit_lifting = '';
    //       this.formData.view_lifting = '';

    //     })
    //     .catch((error) => {
    //       this.sendingToQB = false;
    //       this.createdRecord = false;

    //       this.showCreateRecordPopUp = true;

    //       setTimeout(() => {
    //         this.showCreateRecordPopUp = false;
    //       }, 3000);
    //       console.log("error", error);
    //     });
        
    // },
  },
};

</script>

<style scoped>

  * {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
  }

  .switcher-content * {
    font-size: 14px;
  }

  .modal {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
  }

  .modal__mask {
    display: flex;
    flex-grow: 1;
    overflow-y: hidden;
    flex-direction: column;
  }

  .modal__wrapper {
    max-height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  .modal__container {
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .modal__container::-webkit-scrollbar {
    width: 5px;
    height: 10px;
    background-color: #f9f9fd;
  }

  .modal__container::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #2d375744;
  }

  .modal__container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
    background-color: #e3e9f1;
  }

  .modal__container-loader {
    overflow-y: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
  }

  .modal__header {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background: #F6FAFF;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 15px;
    flex: 0 0 auto;
  }

  .modal__header-content {
    padding-top: 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-right: 40px;
    align-items: center;
  }

  .modal__header-panel {
    display: flex;
  }

  .modal__title {
    padding: 5px 20px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    color: #2d3757;
    max-width: 70%;
    justify-items: center;
    text-align: left;
    flex-grow: 1;
  }

  .modal__body {
    flex: 1 1 auto;
    overflow-y: auto;
    flex-direction: column;
    display: flex;
  }

  .modal__close-button {
    border: 1px solid #2d3757;
    border-radius: 2px;
    padding: 14px 28px;
    margin-right: 15px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  .modal__close-button:hover {
    background-color: #50629b;
    color: white;
  }

  .modal__close-button:hover .close-button__img {
    filter: invert(100%) brightness(1000%);
  }

  .close-button__img {
    width: 16px;
    height: 16px;
    background: url(@/assets/btn-close.svg) no-repeat center center;
    margin-right: 10px;
  }

  .modal__save-button {
    background: #2d3757;
    border-radius: 2px;
    padding: 14px 28px;
    color: #ffffff;
    margin-left: 15px;
    cursor: pointer;
    border: 1px solid transparent;
    box-sizing: border-box;
    position: relative;
    width: 114px;
  }

  .modal__save-button:hover {
    background-color: #50629b;
    border: 1px solid transparent;
    outline: none;
  }

  .save-button__body {
    display: flex;
    align-items: center;
  }

  .save-button__img {
    width: 16px;
    height: 16px;
    background: url(@/assets/btn-save.svg) no-repeat center center;
    margin-right: 10px;
  }

  .switch-bar {
    display: flex;
    flex: 0 0 auto;
    background: #F6FAFF;
    padding: 0 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid #D3DFED;
  }

  .switcher-content {
    flex: 1 1 auto;
    max-height: 100%;
    overflow-y: auto;
    padding-left: 30px;
    padding-right: 30px;
  }

  .switcher-content::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f9f9fd;
  }

  .switcher-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #2d375744;
  }

  .switcher-content::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 20px;
    background-color: #e3e9f1;
  }

  .field-title {
    padding: 8px 0;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #6c6f7d;
    display: flex;
    align-items: center;
  }

  .switcher-content__data-input {
    border: 1px solid #d3dfed;
    border-radius: 2px;
    padding: 12px;
    font-size: 16px;
    font-family: "Lato";
  }

  .switcher-content__data-input.checkbox {
    width: auto;
  }

  .switcher-content__sub-tab-table td {
    padding: 5px;
    border: 5px solid transparent;
    vertical-align: top;
  }

  .switcher-content__sub-tab-table td .field-title {
    box-sizing: border-box;
    max-width: 100%;
    position: relative;
  }

  .switcher-content__sub-tab-table td .switcher-content__data-input {
    box-sizing: border-box;
    width: 240px;
    max-width: 240px;
  }

  .switcher-content__sub-tab-table td .switcher-content__data-input.checkbox {
    width: auto;
  }

  .loader {
    top: 46%;
    left: 46%;
  }

</style>
