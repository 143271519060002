<template>
  <div class="home">
    <div class="reports">
      <div class="reports__wrap">
        <div class="reports__body">
          <div class="report__item">
            <div class="report__wrap">
              <div class="report-panel">
                <div class="report__title">
                  <div class="report__collapse" @click="showBody = !showBody">
                    <span v-if="showBody">-</span>
                    <span v-else>+</span>
                  </div>
                  <h2 @click="showBody = !showBody">Charterers</h2>
                  <div class="create-record-wrap">
                    <button
                      class="create-record"
                      @click="
                        () => {
                          this.$router.push({
                            name: 'CreateCharterersRecord',
                            params: {},
                          });
                        }
                      "
                    >
                      New Charterer
                    </button>
                  </div>
                </div>

                <!-- <div class="preloader" v-if="gettingReport">
                  <CustomLoader />
                </div> -->

                <!-- <div v-else-if="reportData.length != 0 && showBody"> -->
                <div v-if="showBody">

                  <input v-model="searchText" class="search-input" placeholder="Search..." @change="filteredItems()">

                  <div class="report-body wrap-scroll">
                    <table class="report-table">
                      <thead>
                        <tr class="report-table__head" style="position: sticky">
                          <th class="report-table__sticky report-table__col-pencil"></th>
                          <th>
                            <div>Charterer Name</div>
                          </th>
                          <th>
                            <div>Display on the website</div>
                          </th>
                        </tr>
                      </thead>
                      
                      <!-- <tbody>
                        <tr v-for="(row, index) in filteredItems" :key="index">
                          <td class="report-table__sticky report-table__col-pencil">
                            <div
                              class="record__edit-button"
                              @click="() => {
                                this.$router.push({ name: 'EditUserRecord', params: { id: row.id, open: true } })
                              }"
                            ></div>
                          </td>
                          <td>
                            <div class="report-table__item">
                              {{ row.name }}
                            </div>
                          </td>
                          <td>
                            <div class="report-table__item">
                              {{ row.email }}
                            </div>
                          </td>
                          <td class="column-role">
                            <div class="report-table__item">
                              {{ row.role }}
                            </div>
                          </td>
                          <td class="column-approved">
                            <div class="report-table__item">
                              <input type="checkbox" :checked="row.approved" disabled>
                            </div>
                          </td>
                          <td class="column-add-lifting">
                            <div class="report-table__item">
                              <input type="checkbox" :checked="row.add_lifting" disabled>
                            </div>
                          </td>
                          <td class="column-edit-lifting">
                            <div class="report-table__item">
                              <input type="checkbox" :checked="row.edit_lifting" disabled>
                            </div>
                          </td>
                          <td class="column-view-lifting">
                            <div class="report-table__item">
                              <input type="checkbox" :checked="row.view_lifting" disabled>
                            </div>
                          </td>
                          <td class="column-charterer">
                            <div class="report-table__item">
                                <span>
                                  {{
                                    row.charterers
                                      .map(id => this.charterers.find(c => c.id === id)?.name)
                                      .filter(name => name)
                                      .join(', ')
                                  }}
                                </span>
                            </div>
                          </td>
                          <td class="column-role">
                            <div class="report-table__item">
                              {{ row.default_charterer }}
                            </div>
                          </td>
                        </tr>
                      </tbody> -->
                        
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {
      showBody: true,
    };
  },

  mounted() {
  },

  computed: {
  },

  methods: {
  },

};
</script>

<style scoped>
  .reports {
    padding: 0 30px;
  }

  .report-panel {
    max-width: 100%;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .report__title {
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 48px;
    color: #2d3757;
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .report__collapse {
    border: 3px solid #d3dfed;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75em;
    margin-right: 15px;
    color: #2d3757;
    background: #f6faff;
  }

  .search-input {
    margin-left: 32px;
    width: 420px;
    height: 40px;
    padding: 10px;
    box-sizing: border-box;
    outline: none;
    border: var(--ms-border-width,1px) solid var(--ms-border-color,#d1d5db);
    border-radius: var(--ms-radius,4px);
    background: var(--ms-bg,#fff);
    font-size: var(--ms-font-size,1rem);
    min-height: calc(var(--ms-border-width, 1px)*2 + var(--ms-font-size, 1rem)*var(--ms-line-height, 1.375) + var(--ms-py, .5rem)*2);
  }

  .search-input::placeholder {
    color: #9ca3af;
  }

  .search-input:focus {
    box-shadow: 0 0 0 var(--ms-ring-width,3px) var(--ms-ring-color,rgba(16,185,129,.18823529411764706));
  }

  .report-body {
    overflow-y: auto;
    overflow-x: auto;
    position: relative;
    margin-top: 10px;
    padding: 0 5px 45px 0;
  }

  .report-table {
    width: 100%;
    table-layout: auto;
    border-collapse: collapse;
    position: relative;
  }

  th {
    position: sticky;
    z-index: 1;
    border: 0;
    padding: 5px 10px;
    color: rgb(255, 255, 255);
    -webkit-font-smoothing: antialiased;
    cursor: default;
    vertical-align: middle;
    font-family: "Lato";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: #2d3757;
    min-height: 70px;
  }

  .report-table .report-table__head th > div {
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>